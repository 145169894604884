
import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";
import { isProd } from "@/services/process-service";
import { getTokenFromStorage } from "@/services/storage-service";
import { sleep } from "@/utils/convert";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "IFrame",
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "1200px",
    },
    iframe_id: {
      type: String,
      default: Math.ceil(Date.now() / 1000).toString(),
    },
  },
  components: {},
  data: () => ({
    loaded: false,
  }),
  created() {},
  mounted() {},
  computed: {
    ...mapGetters("loading", ["isLoading"]),
    ...mapGetters("internationalization", ["getLanguage"]),
  },
  methods: {
    handlerError(event: any) {
      this.loaded = true;
    },
    async handlerLoad(event: any) {
      this.loaded = true;

      if (this.iframe_id !== MARKET_PLACE_REF) return;
      await sleep(100);
      const result = await this.processSync();
      if (!isProd()) console.debug(`[DSP] IFrame::handlerLoad`, { result });
    },
    async processSync() {
      try {
        console.log(`[DSP] IFrame::FirstLanguage: ${this.getLanguage}`);
        const token = getTokenFromStorage();
        const locale = this.getLanguage;
        if (!token) {
          throw new Error("No se encontró token en el localStorage");
        }
        this.sendIframeMessage({ token, locale });
        return Promise.resolve(`[DSP] IFrame::processSync: Token enviado al iframe.`);
      } catch (error) {
        console.error(`[DSP] IFrame::processSync`, { error });
        return Promise.reject(error);
      }
    },
    sendLocale() {
      const locale = this.getLanguage;
      this.sendIframeMessage({ locale });
    },
    sendIframeMessage(contentMessage: { locale?: string; token?: string }) {
      const iframeElement = this.$refs[MARKET_PLACE_REF];
      if (!iframeElement) {
        throw new Error(`No se encontro la instancia de ${MARKET_PLACE_REF}`);
      }
      iframeElement.contentWindow.postMessage(contentMessage, process.env.VUE_APP_FRONT_PERSONS_URL);
    },
  },
  watch: {
    getLanguage(val) {
      console.debug(`[DSP] IFrame::watch::getLanguage`, { val });
      this.sendLocale();
    },
    src(val) {
      this.loaded = false;
    },
  },
});
